<template>
  <div class="mynode">
    <Nav></Nav>
    <div class="mynodeCon">
      <div class="con0 cursor-pointer" @click="JumpNode">
        <div></div>
        <div>{{lang('fanhui')}}</div>
      </div>
      <div class="con1">
        <div class="con1L">
          <p class="reactivePc"><b>{{myInfoObj.name}}</b></p>
          <p class="reactiveMob"><b>{{myInfoObj.name}}</b></p>
          <p class="reactivePc">{{myInfoObj.slogan}}</p>
          <p class="reactiveMob">{{myInfoObj.slogan}}</p>
        </div>
        <template>
          <div class="con1RL con1R cursor-pointer" @click="JumpInfo" v-show="myInfoObj.canDeposit===true">
              <div></div>
              <div>{{lang('xiugaixinxi')}}</div>
        </div>
        <div class="con1R cursor-pointer" @click="showVote" v-show="myInfoObj.canDeposit===true">
              <div></div>
              <div>{{lang('toupiao')}}</div>
        </div>
        <div class="con1T  cursor-pointer" v-show="myInfoObj.canDeposit===false" style="color: red">
              {{lang('pr')}}
        </div>
        </template>
      </div>
      <div class="con2">
        <div class="conNum">
          <div class="a">
            <p><b>{{myInfoObj.rate}}%</b></p>
            <p class="n">{{lang('shouyifenpeibili')}}</p>
          </div>
          <div class="a">
            <p><b>{{BigNumberStr(myInfoObj.totalAmount,18,2)}}</b></p>
            <p class="n">{{lang('nodetotalpl')}}</p>
          </div>
          <div class="a">
            <p><b>{{BigNumberStr(myInfoObj.totalReward,18,3)}}</b></p>
            <p class="n">{{lang('leijifafangshouyi')}}</p>
          </div>
          <div class="a">
            <p v-if="myInfoObj.canDeposit==true"><b>{{BigNumberStr(myInfoObj.dailyReward, 18,3)}}</b></p>
            <p v-if="myInfoObj.canDeposit==false"><b>0</b></p>
            <p class="n">{{lang('estimated')}}</p>
          </div>
        </div>
      </div>
      <div class="con3">
        <div class="top">
          <div class="topL">{{lang('jiediancanxuanzhiyashu')}}</div>
          <div class="topR ">
            <span v-if="myInfoObj.totalAmount==0">{{ BigNumberStr(myInfoObj.depositAmount, 18) }}&nbsp; &nbsp;丨0%</span>
           <span  v-if="myInfoObj.totalAmount!=0">{{ BigNumberStr(myInfoObj.depositAmount, 18) }}&nbsp; &nbsp;丨&nbsp;&nbsp;{{BigNumberStr((myInfoObj.depositAmount/myInfoObj.totalAmount)* 100,0,3)}}%</span>
            <span class="cursor-pointer" v-if="getAllowanceNum <= 0"  @click="getApprove">{{lang('a_shouquan')}}</span>
            <span class="cursor-pointer" v-if="getAllowanceNum > 0"  @click="showRede">{{lang('a_shuhui')}}</span>
          </div>
        </div>
        <div class="bot">
          <div class="botL">{{lang('jiedianzhuanshulianjie')}}</div>
          <div class="botR">
          <span class="reactiveMob">{{cutNameLink(dcescription+$store.state.accounts[0])}}</span>
          <span class="reactivePc">{{dcescription+$store.state.accounts[0]}}</span>
            <van-button
            type="primary"
            class="btn3"
            v-clipboard:copy="dcescription+$store.state.accounts[0]"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            ></van-button>
          </div>
        </div>
        <div class="pending">
          <div class="pendindL">
            {{lang('pendingrevenue')}}
          </div>
          <div class="pendingR">
            <span>{{BigNumberStr(myInfoObj.depositReward,18,3)}}</span>
            <span class="cursor-pointer" @click="getPleReceive" >{{lang('receive')}}</span>
          </div>
        </div>
         <div class="pending1 pending">
          <div class="pendindL">
            {{lang('pendingrevenue1')}}
          </div>
          <div class="pendingR">
            <span>{{BigNumberStr(myInfoObj.voteReward,18,3)}}</span>
            <span class="cursor-pointer" @click="getReceive" >{{lang('receive')}}</span>
          </div>
        </div>
      </div>
      <div class="con5">
        <div class="title">
          <div class="titleL">
            <span>{{lang('pledgeUserList')}}</span>
          </div>
          
        </div>
      </div>
      <div class="zanwu" v-if="getMyList.length==0">{{lang('zanwuoupiao')}}</div>
      <div class="con4" v-else>
        <div class="conte" v-for="(item,index) in getMyList" :key="index">
          <div class="contL">
            <span v-if="index >= 3">{{ index+1 }}</span>
            <span v-if="index < 3"></span>
            <span></span>
            <span>{{cutAccount(item.addr)}}</span>
          </div>
          <div class="contR">
            <span>{{BigNumberStr(item.amount,18)}} TFI</span>
            <span> | </span>
            <span>{{lang('percentage')}}{{BigNumberStr((item.amount / myInfoObj.totalAmount) * 100,0,3)}}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../../components/Nav/Index";
import { lang, Toast } from "../../tools";
import * as metaMaskTools from "../../tools/chain";
import {cutAccount,cutNameH,cutNameLink} from '../../tools/index'
import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
import { busEvent } from '../../components/busEvent'
export default {
  name: "mynode",
  components: { Nav },
  mixins: [mixin1, mixin2, mixin3],
  created() {
    this.$route.params.flagShowVal=true
    let this_ = this
    busEvent.$on('getBusMyNodeVote',function(val){
      this_.getNodeMyInfo();
      this_.getMyNodeList()
    })
   
  },
  mounted() {
  },
  computed: {
    account() {
      return this.$store.state.accounts[0];
    },
    query() {
      return  this.$route.query.projectId;
    },
  },
  data() {
    return {
      myInfoObj:{
        rate:0,
        totalAmount:0,
        totalReward:0,
        dailyReward:0,
        depositAmount:0,
        depositReward:0,
        name:'',
        slogan:'',
        voteReward:0,
        canDeposit:true

      },
      dcescription: 'http://3.0.61.146:7000' + '/#/nodelist?projectId=',
      getMyList:[],
      noAddr:'',
      status:false,
      fShow:true,
      isTrue:false,
      getAllowanceNum:0,
      snftNum:0
    };
  },

  methods: {
    initPage() {
      this.getNodeMyInfo()
      this.getMyNodeList()
      this.getNodeList()
      this.getOrRede()
      this.getAllowance()
      this.getbalanceOfSNFT()
    },
    initData() {
    },
    cutNameH(name){
        return cutNameH(name);
    },
    cutNameLink(nameL){
        return cutNameLink(nameL);
    },
    onCopy() {
      Toast(this.lang('a_fuzhichneggon'));
    },
    onError() {
      Toast(this.lang('fuzhishibai'));
    },
    cutAccount(account){
        return cutAccount(account);
      },
    showVote(){
        this.$store.commit('setState', {
          myVoteShow: true
        })
      },
    JumpNode() {
      this.$router.push('/node');
    },
    onRefresh(accounts) {
        this.initPage();
    },
    async getAllowance() {
      var res=await metaMaskTools.getRedeAllowance(this.account);
      this.getAllowanceNum=res
    },
    async getApprove() {
      await metaMaskTools.getRedeApprove(this.account);
      Toast(this.lang('a_shouquanchenggong'))
      this.getAllowance()
    },
    hide() {
      this.authorShow=false
    },
    JumpInfo() {
      this.$router.push('/ModifyInfor');
    },
    async getReceive() {
      if(this.myInfoObj.voteReward<=0) {
        Toast(this.lang('nono'))
      } else {
        var res = await metaMaskTools.getReceive(this.account);
        this.getNodeMyInfo()
        Toast(this.lang('lingquchenggong'))
      }
      
    },
    async getPleReceive() {
      if(this.myInfoObj.depositReward<=0) {
        Toast(this.lang('nono'))
      } else {
        var res = await metaMaskTools.getPleReceive(this.account);
        this.getNodeMyInfo()
        Toast(this.lang('lingquchenggong'))
      }
      
    },
    async getNodeMyInfo() {
      var res = await metaMaskTools.getNodeMyInfo(this.account);
      console.log(res,'我的节点详情');
      this.myInfoObj=res
    },
    async getOrRede() {
      var res = await metaMaskTools.getOrRede(this.account);
      this.isTrue=res
    },
    async getbalanceOfSNFT() {
      var res = await metaMaskTools.getbalanceOfSNFT(this.account);
      this.snftNum = this.BigNumberStr(res, 18);
    },
    async showRede() {
      if(this.isTrue==false) {
        Toast(this.lang('bukeshuhui'))
        this.hide()
      } else {    
        if(this.snftNum/1<this.BigNumberStr(this.myInfoObj.depositAmount,18)) {
          Toast(this.lang('zhanghuyuebuzu'))
          return
        } else{
          var res = await metaMaskTools.getPleRede(this.account);
          // this.$store.commit('setState', {
          //   authorShow: true,
          //   transactionHash:res.transactionHash
          // })
          this.getNodeMyInfo()
          // this.hide()
        }
        
      }
    },
    async getNodeList() {
      var res = await metaMaskTools.getNodeList(this.account,this.fShow);
      this.getList=res
      this.noAddr=res.nodeAddr
    },
    async getMyNodeList() {
      this.fShow = this.$route.params.flagShowVal
      var res = await metaMaskTools.getMyNodeList(this.account,this.fShow);
      console.log(res,111);
      this.getMyList=res
    },
    lang(key){
        return lang(key);
    },
  },
};
</script>

<style scoped lang="less">
.cursor-pointer{
  cursor: pointer;
}
@media (min-width: 769px) {
  .mynodeCon {
  box-sizing: border-box;
  width: 100%;
  padding: 110px 40px 0 240px;
  .con0 {
     z-index: 40;
    position: fixed;
    text-align: center;
    width: 80px;
    height: 28px;
    line-height: 25px;
    border-radius: 14px;
    border: 1px solid #fff;
    background-color: #152e55;
    color: #fff;
    padding: 0 10px;
    div:nth-of-type(1) {
      width: 12px;
      height: 12px;
      background: url("../../assets/img/fenhui.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    div:nth-of-type(2) {
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
    }
  }
  .con1 {
     z-index: 40;
    position: fixed;
    display: flex;
    margin-top: 60px;
    color: #fff;
    width: 100%;
    p:nth-of-type(2) {
      font-size: 12px;
      margin-top: 8px;
    }
    .con1R {
       position: fixed;
       text-align: center;
        right: 40px;
        width: 80px;
        height: 28px;
        line-height: 25px;
        border-radius: 14px;
        border: 1px solid #fff;
        background-color: #152e55;
        color: #fff;
        padding: 0 10px;
    div:nth-of-type(1) {
      width: 12px;
      height: 12px;
      background: url("../../assets/img/fenhui.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    div:nth-of-type(2) {
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
    }
    }
    .con1RL {
      // width: 160px;
      width: 100px;
      margin-right: 100px;
    }
    .con1T {
        position: fixed;
        right: 40px;
    }
  }
  .con2 {
     z-index: 40;
    margin-top: 140px;
    width: 100%;

    p {
      font-size: 15px;
      color: #fff;
    }
    .conNum {
      border-radius: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      background: linear-gradient(#0077c5, #0045bb);
      margin-top: 20px;
      .a {
        text-align: center;
        width: 33.3%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
      .a:nth-of-type(4) {
        border-right: 0;
      }
    }
  }
    .zanwu {
    color: rgba(#fff,.3);
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }
  .con3 {
    width: 100%;
    margin-top: 25px;
    height: 205.5px;
    background-color: rgba(#0f111f, 0.4);
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    padding: 0 15px 0 9.5px;
    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;
      border-bottom: 1px solid #ccc;
      .topR {
        span:nth-of-type(2) {
          display: inline-block;
          width: 65px;
          height: 22.5px;
          line-height: 22.5px;
          border: 1px solid #0077c5;
          text-align: center;
          margin-left: 20px;
          color: #0077c5;
          border-radius: 10px;
        }
      }
    }
    .bot {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;
      border-bottom: 1px solid #ccc;
      .botR {
      display: flex;
      align-items: center;
        .btn3 {
          padding: 0;
          border: 0;
          display: inline-block;
          width: 15px;
          height: 15px;
          background: url("../../assets/img/fz.png") no-repeat;
          background-size: 100% 100%;
          margin-left: 20px;
        }
      }
    }
    .pending {
      display: flex;
      justify-content: space-between;
      height: 51.5px;
      line-height: 51.5px;
      border-bottom: 1px solid #ccc;
      .pendingR {
        span:nth-of-type(2) {
          display: inline-block;
          width: 65px;
          height: 22.5px;
          line-height: 22.5px;
          border: 1px solid #0077c5;
          text-align: center;
          margin-left: 20px;
          color: #0077c5;
          border-radius: 10px;
        }
      }
    }
    .pending1 {
      border-bottom: 0;
    }
  }
  .con5 {
    margin-top: 25px;
    color: #fff;
    font-size: 12px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .titleR {
        span {
          display: inline-block;
          margin-left: 24px;
        }
        span:nth-of-type(6) {
          width: 30px;
          height: 15px;
          background: url("../../assets/img/light.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
        span:nth-of-type(7) {
          width: 30px;
          height: 15px;
          background: url("../../assets/img/an.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }
  .con4 {
    margin-top: 20px;
    width: 100%;
    font-size: 10px;
    border-radius: 10px;
    .conte {
      width: 100%;
      padding: 0 15px 0 9.5px;
      background-color: rgba(#0f111f, 0.4);
      display: flex;
      justify-content: space-between;
      height: 51.5px;
      line-height: 51.5px;
      color: #fff;
      border-bottom: 1px solid rgba(#ccc, 0.4);
    }
    .conte:last-of-type {
      border-bottom:0
    }
    .contL {
      span:nth-of-type(1) {
        display: inline-block;
        width: 15px;
        height: 14.5px;
        margin-left: 4px;
      }
      span:nth-of-type(2) {
        display: inline-block;
        margin-left: 12px;
        vertical-align:middle;
      }
    }
    .contR {
      span {
        display: inline-block;
        margin-left: 20px;
      }
    }
    .conte:nth-of-type(1) .contL span:nth-of-type(1) {
      display: inline-block;
        width: 15px;
        height: 14.5px;
        background: url("../../assets/img/one.png") no-repeat;
        background-size: 100% 100%;
        margin:0 0 -3px 0;
    }
    .conte:nth-of-type(2) .contL span:nth-of-type(1) {
      display: inline-block;
        width: 15px;
        height: 14.5px;
        background: url("../../assets/img/two.png") no-repeat;
        background-size: 100% 100%;
        margin:0 0 -3px 0;
    }
    .conte:nth-of-type(3) .contL span:nth-of-type(1) {
      display: inline-block;
        width: 15px;
        height: 14.5px;
        background: url("../../assets/img/three.png") no-repeat;
        background-size: 100% 100%;
        margin:0 0 -3px 0;
    }
    p {
      line-height: 20px;
    }
  }
}
  }

@media (max-width: 768px){
  .Vote {
      .van-dialog {
        width: 300px;
      }

      .wrap {
        > .sec1 {
          padding: 0 20px 15px;

          .img1 {
            right: 21px;
            top: 16px;
          }

          .d1 {
            padding: 0 0 0 20px;
            height: 45px;
            line-height: 45px;
            font-size: 13px;
            margin-bottom: 10px;
          }
          .d6,.d7,.d8,.d9,.d10{
            font-size: 10px;
          }
          .d8{
            button{
              height: 40px;
              line-height: 40px;
              font-size: 13px;
            }
          }
        }
      }
    }
  .mynodeCon {
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px 0 ;
  .con0 {
    text-align: center;  
    margin-top: 20px;
    width: 80px;
    height: 28px;
    line-height: 25px;
    border-radius: 14px;
    border: 1px solid #fff;
    background-color: #152e55;
    color: #fff;
    padding: 0 10px;
    div:nth-of-type(1) {
      width: 12px;
      height: 12px;
      background: url("../../assets/img/fenhui.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    div:nth-of-type(2) {
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
    }
  }
  .con1 {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    color: #fff;
    width: 100%;
    .con1L {
      word-wrap:break-word;
      word-break:normal; 
      word-break:break-all; 
      font-size: 12px;   
    }
    .con1R {
       text-align: center;
       white-space:nowrap;
        right: 15px;
        width: 80px;
        height: 28px;
        line-height: 25px;
        border-radius: 14px;
        border: 1px solid #fff;
        background-color: #152e55;
        color: #fff;
        padding: 0 10px;
    div:nth-of-type(1) {
      width: 12px;
      height: 12px;
      background: url("../../assets/img/fenhui.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    div:nth-of-type(2) {
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
    }
    }
    .con1RL {
      width: 1.6rem;
      margin-right: -0.8rem;
    }
    .con1T {
      
    }
  }
  .zanwu {
    color: rgba(#fff,.3);
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }
  .con2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    p {
      font-size: 12px;
      color: #fff;
    }
    .conNum {
      border-radius: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      background: linear-gradient(#0077c5, #0045bb);
      margin-top: 20px;
      .a {
        text-align: center;
        width: 33.3%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
      .a:nth-of-type(4) {
        border-right: 0;
      }
    }
  }
  .con3 {
    margin-top: 20px;
    width: 100%;
    background-color: rgba(#0f111f, 0.4);
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    padding: 0 0.1rem 0 0.075rem;
    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;
      border-bottom: 1px solid #ccc;
      .topR {
        span:nth-of-type(2) {
          display: inline-block;
          padding: 0 5px;
          height: 22.5px;
          line-height: 22.5px;
          border: 1px solid #0077c5;
          text-align: center;
          margin-left: 20px;
          color: #0077c5;
          border-radius: 10px;
        }
      }
    }
    .bot {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;    
      .botR {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn3 {
          padding: 0;
          border: 0;
          display: inline-block;
          width: 0.13rem;
          height: 0.13rem;
          background: url("../../assets/img/fz.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin: -3px 0 0 5px;
        }
      }
    }
  }
  .con5 {
    margin-top: 25px;
    color: #fff;
    font-size: 12px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .titleR {
        span {
          display: inline-block;
          margin-left: 24px;
        }
        span:nth-of-type(6) {
          width: 30px;
          height: 15px;
          background: url("../../assets/img/light.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
        span:nth-of-type(7) {
          width: 30px;
          height: 15px;
          background: url("../../assets/img/an.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }
  .con4 {
    margin-top: 20px;
    width: 100%;
    font-size: 10px;
    border-radius: 10px;
    .conte {
      width: 100%;
      padding: 0 15px 0 9.5px;
      background-color: rgba(#0f111f, 0.4);
      display: flex;
      justify-content: space-between;
      height: 51.5px;
      line-height: 51.5px;
      color: #fff;
      border-bottom: 1px solid rgba(#ccc, 0.4);
    }
    .conte:last-of-type {
      border-bottom:0
    }
    .contL {
      span:nth-of-type(1) {
        display: inline-block;
        width: 15px;
        height: 14.5px;
        margin-left: 4px;
      }
      span:nth-of-type(2) {
        display: inline-block;
        margin-left: 12px;
        vertical-align:middle;
      }
    }
    .contR {
      span {
        display: inline-block;
        margin-left: 20px;
      }
    }
    .conte:nth-of-type(1) .contL span:nth-of-type(1) {
      display: inline-block;
        width: 15px;
        height: 14.5px;
        background: url("../../assets/img/one.png") no-repeat;
        background-size: 100% 100%;
        margin:0 0 -3px 0;
    }
    .conte:nth-of-type(2) .contL span:nth-of-type(1) {
      display: inline-block;
        width: 15px;
        height: 14.5px;
        background: url("../../assets/img/two.png") no-repeat;
        background-size: 100% 100%;
        margin:0 0 -3px 0;
    }
    .conte:nth-of-type(3) .contL span:nth-of-type(1) {
      display: inline-block;
        width: 15px;
        height: 14.5px;
        background: url("../../assets/img/three.png") no-repeat;
        background-size: 100% 100%;
        margin:0 0 -3px 0;
    }
    p {
      line-height: 20px;
    }
  }
}
}
</style>
